.flex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.flexWrap {
  flex-wrap: wrap;
}

.alignStart,
.itemsStart {
  align-items: flex-start;
}

.alignEnd,
.itemsEnd {
  align-items: flex-end;
}

.alignCenter,
.itemsCenter {
  align-items: center;
}

/* .itemsBaseline {
  align-items: baseline;
} */

/* .itemsStretch {
  align-items: stretch;
} */

/* .selfStart {
  align-self: flex-start;
} */

/* .selfEnd {
  align-self: flex-end;
} */

/* .selfCenter {
  align-self: center;
} */

/* .selfBaseline {
  align-self: baseline;
} */

/* .selfStretch {
  align-self: stretch;
} */

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyCenter {
  justify-content: center;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyAround {
  justify-content: space-around;
}

.contentStart {
  align-content: flex-start;
}

.contentEnd {
  align-content: flex-end;
}

.contentCenter {
  align-content: center;
}

.contentBetween {
  align-content: space-between;
}

.contentAround {
  align-content: space-around;
}

.contentStretch {
  align-content: stretch;
}

/* Fix for Chrome 44 bug: https://code.google.com/p/chromium/issues/detail?id=506893 */

.flexGrow {
  flex: 1 1 auto;

  /* 1 */
  min-height: 0;
  min-width: 0;
}

.flexNone {
  flex: 0 0 auto;
}

.orderFirst {
  order: -1;
}

.orderLast {
  order: 99999;
}

.flex.equal > * {
  flex: 1;
}
