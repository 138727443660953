/* display */
.block {
  display: block;
}

.inline {
  display: inline-block;
}

/* position */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  /*
    IE11 Fallback: when sticky is not supported, fall back to relative positioning.
    We do this so if there is a zIndex set on sticky, the stacking context will be correct
  */
  position: relative;
  position: sticky;
}
/* overflow */

.overflowHidden {
  overflow: hidden;
}

.overflowScroll {
  overflow: scroll;
}

.overflowScrollX {
  overflow-x: scroll;
  overflow-y: hidden;
}

.overflowScrollY {
  overflow-x: hidden;
  overflow-y: scroll;
}

.overflowAuto {
  overflow: auto;
}
