@import url('../fonts/PT Root UI_Bold/PT Root UI_Bold.css');
@import url('../fonts/PT Root UI_Light/PT Root UI_Light.css');
@import url('../fonts/PT Root UI_Medium/PT Root UI_Medium.css');
@import url('../fonts/PT Root UI_Regular/PT Root UI_Regular.css');

:root {
  --light: 'PTRootUIWebLight';
  --regular: 'PTRootUIWebRegular';
  --medium: 'PTRootUIWebMedium';
  --bold: 'PTRootUIWebBold';
  --rest: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.light {
  font-family: var(--light), var(--rest);
}
.regular {
  font-family: var(--regular), var(--rest);
}
.medium {
  font-family: var(--medium), var(--rest);
}
.bold {
  font-family: var(--bold), var(--rest);
}

.fz12 {
  font-size: 12px;
}

.fz14 {
  font-size: 14px;
}

.fz20 {
  font-size: 20px;
}

.heading {
  font-size: 40px;
}

.text {
  font-size: 28px;
  line-height: 1.4;
}

.tac {
  text-align: center;
}

.ttu {
  text-transform: uppercase;
}

.h1 {
  font-family: var(--bold), var(--rest);
  font-size: 3rem;
}
.h2 {
  font-family: var(--bold), var(--rest);
  font-size: 2rem;
}
.h3 {
  font-family: var(--bold), var(--rest);
  font-size: 1.5rem;
}
.h4 {
  font-family: var(--bold), var(--rest);
  font-size: 1.25rem;
}
.h5 {
  font-family: var(--bold), var(--rest);
  font-size: 1rem;
}
.h6 {
  font-family: var(--bold), var(--rest);
  font-size: 0.875rem;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
