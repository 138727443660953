.content {
  flex: 1 0 auto;
  padding-top: 64px;
}

/*phone-only*/
@media (max-width: 599px) {
  .content {
    width: 100vw;
    overflow-x: hidden;
  }
}

.container {
  width: 90vw;
  margin: 0 auto;
}

.condenced {
  width: 960px;
  margin: 0 auto;
}

/*phone-only*/
@media (max-width: 599px) {
  .container,
  .condenced {
    width: 100%;
    margin: 0;
    padding: 0 8px;
  }
}

.page {
  padding: 40px 0;
}

.columns {
  margin: 0 -10px;
}

.column {
  padding: 0 10px;
}

.grid3 {
  width: 33.33%;
}

.grid4 {
  width: 25%;
}

/*phone-only*/
@media (max-width: 599px) {
  .grid3,
  .grid4 {
    width: 100%;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-columns: repeat(6, minmax(50%, 1fr)); */
  grid-column-gap: 20px;
  grid-row-gap: 40px;
}

.grid-item {
  min-width: 100px;
}

.a-link {
  text-decoration: underline;
}

.a-link:hover {
  text-decoration: none;
}

.a-link + .a-link {
  margin-left: 16px;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
}

.avatar {
  height: 100%;
  width: 100%;
  border-radius: 128px;
}

.menu-item {
  height: 40px;
}

.cover {
  object-fit: cover;
}
