/* Generated by ParaType (http://www.paratype.com)*/
/* Font PT Root UI: Copyright ? ParaType, 2018. All rights reserved.*/

@font-face {
  font-family: 'PTRootUIWebRegular';
  src: url('PT Root UI_Regular.eot');
  src: url('PT Root UI_Regular.eot?#iefix') format('embedded-opentype'),
    url('PT Root UI_Regular.woff2') format('woff2'),
    url('PT Root UI_Regular.woff') format('woff'),
    url('PT Root UI_Regular.ttf') format('truetype'),
    url('PT Root UI_Regular.svg#PTRootUIWeb-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
