.cday {
  width: 64px;
  height: 64px;
  border: 1px solid var(--colorGray5);
  text-align: center;
}

.cday__month {
  padding: 4px;
  font-size: 12px;
  text-transform: uppercase;
  /* color: #fff; */
  /* background: red; */
  color: #ee0000;
}

.cday__date {
  font-size: 28px;
  line-height: 40px;
  vertical-align: middle;
}
