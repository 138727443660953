.slider {
  overflow: hidden;
}

.slides {
  display: flex;
  will-change: transform;
}

.slides > * {
  flex: none;
  width: 100%;
}

.button {
  position: absolute !important;
  top: 50% !important;
  display: block !important;
  transform: translateY(-50%) !important;
  z-index: 1 !important;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  /* vertical-align: middle; */
  background: #fff;
  box-shadow: 0 1px 12px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  user-select: none;
}

.button:first-child {
  left: -20px;
}

.button:last-child {
  right: -20px;
}
