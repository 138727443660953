.dim {
  transition: all 0.14s linear;
}

.dim:hover {
  background: rgba(222, 223, 224, 0.5);
}

.cup {
  cursor: pointer;
}

.cud {
  cursor: default;
}
