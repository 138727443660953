:root {
  --bt: 4px;
}

/* Margins */

.margin0 {
  margin: 0;
}
.margin1 {
  margin: calc(var(--bt) * 1);
}
.margin2 {
  margin: calc(var(--bt) * 2);
}
.margin3 {
  margin: calc(var(--bt) * 3);
}
.margin4 {
  margin: calc(var(--bt) * 4);
}
.margin5 {
  margin: calc(var(--bt) * 5);
}
.margin6 {
  margin: calc(var(--bt) * 6);
}
.margin7 {
  margin: calc(var(--bt) * 7);
}
.margin8 {
  margin: calc(var(--bt) * 8);
}
.margin9 {
  margin: calc(var(--bt) * 9);
}
.margin10 {
  margin: calc(var(--bt) * 10);
}

.marginX0 {
  margin-left: 0;
  margin-right: 0;
}
.marginX1 {
  margin-left: calc(var(--bt) * 1);
  margin-right: calc(var(--bt) * 1);
}
.marginX2 {
  margin-left: calc(var(--bt) * 2);
  margin-right: calc(var(--bt) * 2);
}
.marginX3 {
  margin-left: calc(var(--bt) * 3);
  margin-right: calc(var(--bt) * 3);
}
.marginX4 {
  margin-left: calc(var(--bt) * 4);
  margin-right: calc(var(--bt) * 4);
}
.marginX5 {
  margin-left: calc(var(--bt) * 5);
  margin-right: calc(var(--bt) * 5);
}
.marginX6 {
  margin-left: calc(var(--bt) * 6);
  margin-right: calc(var(--bt) * 6);
}
.marginX7 {
  margin-left: calc(var(--bt) * 7);
  margin-right: calc(var(--bt) * 7);
}
.marginX8 {
  margin-left: calc(var(--bt) * 8);
  margin-right: calc(var(--bt) * 8);
}
.marginX9 {
  margin-left: calc(var(--bt) * 9);
  margin-right: calc(var(--bt) * 9);
}
.marginX10 {
  margin-left: calc(var(--bt) * 10);
  margin-right: calc(var(--bt) * 10);
}

.marginY0 {
  margin-top: 0;
  margin-bottom: 0;
}
.marginY1 {
  margin-top: calc(var(--bt) * 1);
  margin-bottom: calc(var(--bt) * 1);
}
.marginY2 {
  margin-top: calc(var(--bt) * 2);
  margin-bottom: calc(var(--bt) * 2);
}
.marginY3 {
  margin-top: calc(var(--bt) * 3);
  margin-bottom: calc(var(--bt) * 3);
}
.marginY4 {
  margin-top: calc(var(--bt) * 4);
  margin-bottom: calc(var(--bt) * 4);
}
.marginY5 {
  margin-top: calc(var(--bt) * 5);
  margin-bottom: calc(var(--bt) * 5);
}
.marginY6 {
  margin-top: calc(var(--bt) * 6);
  margin-bottom: calc(var(--bt) * 6);
}
.marginY7 {
  margin-top: calc(var(--bt) * 7);
  margin-bottom: calc(var(--bt) * 7);
}
.marginY8 {
  margin-top: calc(var(--bt) * 8);
  margin-bottom: calc(var(--bt) * 8);
}
.marginY9 {
  margin-top: calc(var(--bt) * 9);
  margin-bottom: calc(var(--bt) * 9);
}
.marginY10 {
  margin-top: calc(var(--bt) * 10);
  margin-bottom: calc(var(--bt) * 10);
}

.mt1 {
  margin-top: calc(var(--bt) * 1);
}
.mt2 {
  margin-top: calc(var(--bt) * 2);
}
.mt3 {
  margin-top: calc(var(--bt) * 3);
}
.mt4 {
  margin-top: calc(var(--bt) * 4);
}
.mt5 {
  margin-top: calc(var(--bt) * 5);
}
.mt6 {
  margin-top: calc(var(--bt) * 6);
}
.mt7 {
  margin-top: calc(var(--bt) * 7);
}
.mt8 {
  margin-top: calc(var(--bt) * 8);
}
.mt9 {
  margin-top: calc(var(--bt) * 9);
}
.mt10 {
  margin-top: calc(var(--bt) * 10);
}

.mb1 {
  margin-bottom: calc(var(--bt) * 1);
}
.mb2 {
  margin-bottom: calc(var(--bt) * 2);
}
.mb3 {
  margin-bottom: calc(var(--bt) * 3);
}
.mb4 {
  margin-bottom: calc(var(--bt) * 4);
}
.mb5 {
  margin-bottom: calc(var(--bt) * 5);
}
.mb6 {
  margin-bottom: calc(var(--bt) * 6);
}
.mb7 {
  margin-bottom: calc(var(--bt) * 7);
}
.mb8 {
  margin-bottom: calc(var(--bt) * 8);
}
.mb9 {
  margin-bottom: calc(var(--bt) * 9);
}
.mb10 {
  margin-bottom: calc(var(--bt) * 10);
}

.ms1 {
  margin-left: calc(var(--bt) * 1);
}
.ms2 {
  margin-left: calc(var(--bt) * 2);
}
.ms3 {
  margin-left: calc(var(--bt) * 3);
}
.ms4 {
  margin-left: calc(var(--bt) * 4);
}
.ms5 {
  margin-left: calc(var(--bt) * 5);
}
.ms6 {
  margin-left: calc(var(--bt) * 6);
}
.ms7 {
  margin-left: calc(var(--bt) * 7);
}
.ms8 {
  margin-left: calc(var(--bt) * 8);
}
.ms9 {
  margin-left: calc(var(--bt) * 9);
}
.ms10 {
  margin-left: calc(var(--bt) * 10);
}

.me1 {
  margin-right: calc(var(--bt) * 1);
}
.me2 {
  margin-right: calc(var(--bt) * 2);
}
.me3 {
  margin-right: calc(var(--bt) * 3);
}
.me4 {
  margin-right: calc(var(--bt) * 4);
}
.me5 {
  margin-right: calc(var(--bt) * 5);
}
.me6 {
  margin-right: calc(var(--bt) * 6);
}
.me7 {
  margin-right: calc(var(--bt) * 7);
}
.me8 {
  margin-right: calc(var(--bt) * 8);
}
.me9 {
  margin-right: calc(var(--bt) * 9);
}
.me10 {
  margin-right: calc(var(--bt) * 10);
}

/* Paddings */

.padding0 {
  padding: 0;
}
.padding1 {
  padding: calc(var(--bt) * 1);
}
.padding2 {
  padding: calc(var(--bt) * 2);
}
.padding3 {
  padding: calc(var(--bt) * 3);
}
.padding4 {
  padding: calc(var(--bt) * 4);
}
.padding5 {
  padding: calc(var(--bt) * 5);
}
.padding6 {
  padding: calc(var(--bt) * 6);
}
.padding7 {
  padding: calc(var(--bt) * 7);
}
.padding8 {
  padding: calc(var(--bt) * 8);
}
.padding9 {
  padding: calc(var(--bt) * 9);
}
.padding10 {
  padding: calc(var(--bt) * 10);
}

.paddingX0 {
  padding-left: 0;
  padding-right: 0;
}
.paddingX1 {
  padding-left: calc(var(--bt) * 1);
  padding-right: calc(var(--bt) * 1);
}
.paddingX2 {
  padding-left: calc(var(--bt) * 2);
  padding-right: calc(var(--bt) * 2);
}
.paddingX3 {
  padding-left: calc(var(--bt) * 3);
  padding-right: calc(var(--bt) * 3);
}
.paddingX4 {
  padding-left: calc(var(--bt) * 4);
  padding-right: calc(var(--bt) * 4);
}
.paddingX5 {
  padding-left: calc(var(--bt) * 5);
  padding-right: calc(var(--bt) * 5);
}
.paddingX6 {
  padding-left: calc(var(--bt) * 6);
  padding-right: calc(var(--bt) * 6);
}
.paddingX7 {
  padding-left: calc(var(--bt) * 7);
  padding-right: calc(var(--bt) * 7);
}
.paddingX8 {
  padding-left: calc(var(--bt) * 8);
  padding-right: calc(var(--bt) * 8);
}
.paddingX9 {
  padding-left: calc(var(--bt) * 9);
  padding-right: calc(var(--bt) * 9);
}
.paddingX10 {
  padding-left: calc(var(--bt) * 10);
  padding-right: calc(var(--bt) * 10);
}

.paddingY0 {
  padding-top: 0;
  padding-bottom: 0;
}
.paddingY1 {
  padding-top: calc(var(--bt) * 1);
  padding-bottom: calc(var(--bt) * 1);
}
.paddingY2 {
  padding-top: calc(var(--bt) * 2);
  padding-bottom: calc(var(--bt) * 2);
}
.paddingY3 {
  padding-top: calc(var(--bt) * 3);
  padding-bottom: calc(var(--bt) * 3);
}
.paddingY4 {
  padding-top: calc(var(--bt) * 4);
  padding-bottom: calc(var(--bt) * 4);
}
.paddingY5 {
  padding-top: calc(var(--bt) * 5);
  padding-bottom: calc(var(--bt) * 5);
}
.paddingY6 {
  padding-top: calc(var(--bt) * 6);
  padding-bottom: calc(var(--bt) * 6);
}
.paddingY7 {
  padding-top: calc(var(--bt) * 7);
  padding-bottom: calc(var(--bt) * 7);
}
.paddingY8 {
  padding-top: calc(var(--bt) * 8);
  padding-bottom: calc(var(--bt) * 8);
}
.paddingY9 {
  padding-top: calc(var(--bt) * 9);
  padding-bottom: calc(var(--bt) * 9);
}
.paddingY10 {
  padding-top: calc(var(--bt) * 10);
  padding-bottom: calc(var(--bt) * 10);
}

.spacing2 > * + * {
  margin-left: calc(var(--bt) * 2);
}

.spacing4 > * + * {
  margin-left: calc(var(--bt) * 4);
}

.spacing6 > * + * {
  margin-left: calc(var(--bt) * 6);
}
