.sticky {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  padding: 16px 0;
  background: #fff;
  border-top: 1px solid var(--colorGray5);
}
