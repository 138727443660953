/* Generated by ParaType (http://www.paratype.com)*/
/* Font PT Root UI: Copyright ? ParaType, 2018. All rights reserved.*/

@font-face {
  font-family: 'PTRootUIWebLight';
  src: url('PT Root UI_Light.eot');
  src: url('PT Root UI_Light.eot?#iefix') format('embedded-opentype'),
    url('PT Root UI_Light.woff2') format('woff2'),
    url('PT Root UI_Light.woff') format('woff'),
    url('PT Root UI_Light.ttf') format('truetype'),
    url('PT Root UI_Light.svg#PTRootUIWeb-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
