.bookmark {
  position: absolute;
  top: 16px;
  right: 16px;
  display: none;
}

.trigger:hover .bookmark {
  display: block;
}

.icon {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 24px;
  text-align: center;
  vertical-align: middle;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.icon:hover {
  background: var(--colorYellow);
}
