.chat-footer {
  min-height: 60px;
  border-top: 1px solid var(--colorGray5);
}

.chat-avatar {
  flex: none;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-color: var(--colorGray5);
  border-radius: 50%;
  margin-right: 16px;
}

.chat-input {
  width: 100%;
  border: 0;
  outline: 0;
  line-height: 24px;
  resize: none;
}

.chat-button {
}
