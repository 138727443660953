.city {
  width: 100%;
  height: 100%;
}

.image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.rounded {
  border-radius: 8px;
  overflow: hidden;
}

.overlay {
  height: 100%;
  width: 100%;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.25);
  transition: background 0.14s;
}

.overlay:hover {
  background: rgba(0, 0, 0, 0.4);
}
