.table {
  display: table;
  width: 100%;
}

.table__row {
  display: table-row;
}

.table__cell {
  display: table-cell;
  vertical-align: middle;
}

.line {
  height: 60px;
  transition: all 0.14s;
}

.line:nth-child(even) {
  background: #fafafa;
}

.line:hover {
  background: var(--colorGray5);
}
