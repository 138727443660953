/* Generated by ParaType (http://www.paratype.com)*/
/* Font PT Root UI: Copyright ? ParaType, 2018. All rights reserved.*/

@font-face {
  font-family: 'PTRootUIWebBold';
  src: url('PT Root UI_Bold.eot');
  src: url('PT Root UI_Bold.eot?#iefix') format('embedded-opentype'),
    url('PT Root UI_Bold.woff2') format('woff2'),
    url('PT Root UI_Bold.woff') format('woff'),
    url('PT Root UI_Bold.ttf') format('truetype'),
    url('PT Root UI_Bold.svg#PTRootUIWeb-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
