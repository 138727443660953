.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.header {
  height: 64px;
  background: #fff;
  border-bottom: 1px solid var(--colorGray5);
}

.logo {
  width: 64px;
  height: 64px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.logo .img {
  display: inline-block;
  height: 80%;
  width: 80%;
  border-radius: 50%;
  transition: all 0.14s;
  background-color: var(--colorYellow);
}

/* .logo:hover {
  background: #fff;
} */

.nav {
  /* position: absolute; */
  /* left: 0; */
  /* right: 0; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link {
  margin: 0 16px;
  font-size: 18px;
  color: var(--colorBlack5);
  text-decoration: none;
  transition: all 0.14s;
  cursor: pointer;
}

.nav-link:hover {
  color: var(--colorBlack);
}

/* .nav-link + .nav-link {
  margin-left: 16px;
} */

.profile-button {
  width: 28px;
  height: 28px;
  display: inline-block;
  background-size: cover;
  background-color: var(--colorGray5);
  border-radius: 50%;
  margin-left: 8px;
}
