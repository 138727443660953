.chip {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  vertical-align: middle;
  padding-left: 4px;
  padding-right: 12px;
  background: var(--colorGrayA02);
  border-radius: 9999px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.14s;

  margin: 4px;
}

.chip:hover {
  background: var(--colorGrayA04);
}

.chip .emoji {
  margin: 0 8px;
}

input:checked ~ .chip {
  background: var(--colorYellow);
}
