.ava {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--colorGray5);
}

.ava.big {
  height: 64px;
  width: 64px;
}

.activity-item {
  padding: 4px 0;
  line-height: 1.4;
}

.activity-text {
  margin-left: 16px;
}
