.profile {
  padding: 40px 0;
  text-align: center;
}

.placeholder {
  height: 200px;
  border-radius: 16px;
  border: 4px dashed var(--colorGray5);
}
