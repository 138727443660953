:root {
  --colorBlack: #000;
  --colorBlack5: #555;
  --colorGray: #aaa;
  --colorGray5: #eee;
  --colorGrayA02: rgba(170, 170, 170, 0.2);
  --colorGrayA04: rgba(170, 170, 170, 0.4);

  --colorYellow: blanchedalmond;
}

.c-gray {
  color: var(--colorGray);
}
