.section {
  margin: 40px 0 120px;
}

.section-link {
  font-size: 24px;
  text-decoration: none;
  color: var(--colorGray);
  transition: all 0.14s;
}

.section-link:hover {
  color: var(--colorBlack5);
}
