@import-normalize;
@import url('./styles/index.module.css');

body {
  min-height: 100vh;
  margin: 0;
  font-family: 'PTRootUIWebRegular', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
}

a {
  color: initial;
  text-decoration: none;
}

textarea {
  font-family: inherit;
}
