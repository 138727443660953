:root {
  --border-width: 1px;
  --border-color: #eee;
  --border-radius: 8px;
}

.square {
  border-radius: 0;
}

.circle {
  border-radius: 50%;
}

.pill {
  border-radius: 999px;
}

.rounded {
  border-radius: var(--border-radius);
}

.roundedTop {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.roundedRight {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.roundedBottom {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.roundedLeft {
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}
