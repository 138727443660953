.button {
  /* display: inline-block; */
  height: 60px;
  line-height: 60px;
  vertical-align: middle;
  text-align: center;
  padding: 0 24px;
  border-radius: 4px;
  cursor: pointer;

  color: black;
  background-color: var(--colorGrayA02);
  transition: all 0.14s;
}

.button:hover {
  background-color: var(--colorGrayA04);
}

.button.flat {
  background-color: transparent;
}

.button.flat:hover {
  background-color: var(--colorGrayA04);
}

.button.sm {
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
}

.button.link {
  background-color: transparent;
}

.button.active {
  background-color: var(--colorGrayA02);
}

.button.circle {
  padding: 0;
}
